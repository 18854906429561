.Services {
  background-color: #F3F6FF;
  padding: 24px 0;
  margin: 48px 0 24px 0;
  font-size: 15px;
}

.Services .inner {
  max-width: 600px;
  margin: auto;
}

.Services .inner .subtitle{
  font-family: Cormorant-Italic;
  font-size: 26px;
  color: #EE7601;
  font-weight: bold;
}

.Services .inner .emphasize{
  font-weight: bold;
}

.Services .inner .caption{
  font-size: 12px;
  margin-top: 10px;
}

.Services .inner .linkContainer{
  margin: 24px 0 48px 0;
}

.Services .inner .link{
  color: #EE7601;
  text-decoration: none;
  border:2px solid #EE7601;
  border-radius: 40px;
  padding: 12px;
  font-weight: bold;
}

@media (max-width: 768px) {}

@media (max-width: 600px) {}