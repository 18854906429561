.Teacher {
  max-width: 1920px;
  min-height: 732px;
  margin: auto auto 150px auto;
  position: relative;
  background-image: url(../../asset/image/teacher_header_bg.png);
  background-repeat: no-repeat;
  background-size: 100% 650px;
  padding-top: 120px;
  overflow-x: hidden;
}

.Teacher>.inner>.inside {
  max-width: 1500px;
  margin: auto;
  position: relative;
  min-height: 1732px;
  overflow: hidden;
}

.Teacher .Header {
  display: flex;
  padding-top: 35px;
}

.Teacher .Header .left {
  flex: 1;
  padding-left: 15%;
  z-index: 1;
}

.Teacher .Header .left .title {
  font-family: Cormorant-Italic, 'Times New Roman', Times, serif;
  font-size: 4.2em;
  margin: 0px;
}

.Teacher .Header .left .name {
  width: 100%;
  font-family: Fraunces, 'Times New Roman', Times, serif;
  font-size: 7em;
  line-height: 0.7em;
  margin: 0;
  margin-bottom: 0.3em;
}

.Teacher .Header .left .description {
  width: 80%;
  font-size: 15px;
  line-height: 25.5px;
}

.Teacher .Header .right {
  flex: 1;
  display: flex;
  align-items: center;
}

.Teacher .Header .right img {
  width: 80%;
  max-width: 500px;
  margin: 0 auto;
}

.Teacher .content {
  max-width: 600px;
  margin: 180px auto auto auto;
  position: relative;
}

.Teacher .title.blue {
  display: flex;
  max-width: 600px;
  margin: auto auto 40px auto;
}

.Teacher .title.blue .text {
  margin: 0 0 0 24px;
  font-size: 38px;
  font-family: Fraunces;
}

.Teacher .content>.text .enhanced {
  font-weight: bold;
}

.Teacher .likes {
  margin-top: 120px;
}

.Teacher .likes .likesContent {
  max-width: 820px;
  margin: auto;
}

.Teacher .likes .likesContent .line {
  display: flex;
}

.Teacher .likes .likesContent .line .Like {
  flex: 1;
  margin: 12px;
}

@media (max-width: 768px) {
  .Teacher .Header {
    padding-top: 0px;
  }

  .Teacher .Header .left {
    padding-left: 24px;
    flex: 1.5;
  }

  .Teacher .Header .left .description {
    width: 95%;
  }

  .Teacher .Header .right {
    overflow: hidden;
  }

  .Teacher .Header .right img {
    width: 120%;
    margin-top: 70px;
  }
}

@media (max-width: 600px) {
  .Teacher .Header {
    flex-direction: column;
    padding: 0 24px;
  }

  .Teacher .Header .left {
    padding-left: 0;
    flex: 0;
  }

  .Teacher .Header .left .title {
    font-size: 3.2em;
  }

  .Teacher .Header .left .punchline {
    font-size: 2.2em;
  }

  .Teacher .Header .right {
    padding-right: 0;
    flex: 0;
  }

  .Teacher .Header .right img {
    width: 90%;
    margin-top: 10px;
  }

  .Teacher .Header .left .name img {
    width: 100%;
    max-width: 100%;
  }

  .Teacher .content,
  .Teacher .likes {
    margin: 68px 24px;
  }

  .Teacher .likes .line {
    flex-direction: column;
  }
}