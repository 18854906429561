.NavBar {
  max-width: 1920px;
  margin: auto;
  position: fixed;
  top: -100px;
  left: 0;
  right: 0;
  z-index: 50;
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
}

.NavBar.fixed {
  top: 0;
  width: 100%;
  z-index: 50;
}

.NavBar.opaque-background {
  background-color: #F3F6FF;
}

.NavBar .inner {
  display: flex;
  flex-direction: row;
  margin: 0px 80px;
}

.NavBar .left {
  padding: 10px;
  flex: 0;
}

.NavBar .center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.NavBar .links {
  align-items: center;
  justify-content: end;
  flex: 1;
}

.NavBar .inner.mobileOnly {
  margin: auto;
  padding: 0px 10px;
  z-index: 100;
}

.NavBar.menuOpened .inner.mobileOnly {
  -webkit-box-shadow: 0 1px 5px 1px #E6E6E6;
  box-shadow: 0 1px 5px 1px #E6E6E6;
}

.NavBar.menuOpened .menuContent {
  height: 100%;
  opacity: 1;
}

.NavBar .mobileOnly .links {
  flex: 0;
}

.NavBar .menuContent {
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
  opacity: 0;
  height: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: -1;
  padding-top: 100px;
  text-align: center;
}

.NavBar .menuContent ul{
  list-style-type: none;
  padding: 0;
}

.NavBar .menuContent ul li{
  margin: 40px 0;
  font-weight: 600;
  font-size: 20px;
}

.NavBar .menuContent ul li a{
  text-decoration: none;
  color: #333333;
}

.NavBar .links>ul {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: 100%;
}

.NavBar .links>ul>li {
  display: flex;
  margin: 8px 30px;
  align-items: center;
}

.NavBar .links>ul>li>a {
  color: #313030;
  text-decoration: none;
}

.NavBar .links li a img {
  flex: 0;
  color: #313030;
  text-decoration: none;
}

.NavBar .arrow-down {
  height: 0.6em;
  margin: 0.2em 0 0 0.2em;
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
}

.NavBar .open .arrow-down {
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.NavBar .activities-dropdown {
  position: relative;
}

.NavBar ul.activities-dropdown_list {
  position: absolute;
  top: 80px;
  left: -15px;
  right: -120px;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  height: 0px;
  overflow: hidden;
  padding: 0;
  -webkit-transition: all 80ms linear;
  -moz-transition: all 80ms linear;
  -ms-transition: all 80ms linear;
  -o-transition: all 80ms linear;
  transition: all 80ms linear;
  opacity: 0;
}

.NavBar .activities-dropdown.open .activities-dropdown_list {
  height: auto;
  opacity: 1;
  -webkit-box-shadow: 0 0 2px 0 #EDEDED;
  box-shadow: 0 0 2px 0 #EDEDED;
}

.NavBar ul.activities-dropdown_list li {
  flex-direction: column;
  background-color: white;
  padding: 15px;
  margin: 0;
}

.NavBar ul.activities-dropdown_list li a {
  text-decoration: none;
  color: black;
}

.NavBar .links a:hover {
  color: #EE7601;
}

@media (max-width: 1024px) {
  .NavBar .links>ul>li {
    margin: 8px 20px;
  }

  .NavBar .links>ul>li:last-child {
    margin: 8px 0px 8px 20px;
  }
}

@media (max-width: 768px) {
  .NavBar .inner {
    margin: 0px 24px;
  }
}

@media (min-width: 600px) {
  .NavBar .inner.mobileOnly {
    display: none;
  }
  .NavBar .menuContent {
    display: none;
  }
}

@media (max-width: 600px) {
  .NavBar {
    background-color: #F3F6FF;
    top: 0;
    -webkit-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
    box-shadow: none;
  }

  .NavBar.opaque-background {
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: #F3F6FF;
  }

  .NavBar .inner.mobileHidden {
    display: none;
  }
}