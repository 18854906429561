.RelevantNumber {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
  width: 185px;
  text-align: center;
}

.RelevantNumber .number {
  font-size: 90px;
  font-family: Fraunces;
  font-weight: 700;
  background-image: url(../../../asset/image/numbers_triangle.svg);    background-size: contain;
  background-repeat: no-repeat;
  display: block;
  background-position: center;
  width: 100%;
}

.RelevantNumber .unit {
  font-family: Fraunces-Italic;
  font-size: 32px;
}