body {
  margin: 0;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
}

* {
  z-index: 10;
}

@font-face {
  font-family: Cormorant-Italic;
  src: url(./asset/font/Cormorant/Cormorant-Italic.ttf);
}

@font-face {
  font-family: Poppins;
  src: url(./asset/font/Poppins/Poppins-Regular.ttf);
}

@font-face {
  font-family: Fraunces;
  src: url(./asset/font/Fraunces_144pt/Fraunces_144pt-Bold.ttf);
}

@font-face {
  font-family: Fraunces-Italic;
  src: url(./asset/font/Fraunces_144pt/Fraunces_144pt-Italic.ttf);
}