.LinkButton {
  border: 2px solid #EE7601;
  border-radius: 40px;
  padding: 13px 25px;
  flex: 0;
  float: left;
}

.LinkButton .inner_link {
  display: flex;
  justify-content: flex-start;
  text-decoration: none;
  color: #EE7601;
  font-weight: bold;
}

.LinkButton .inner_link .img {
  margin-left: 8px;
}